<template>
    <div>
        <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
            <v-card>
                <v-card-title>
                <span class="text-h5">Defect Stock</span>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <span>Tanggal Pencatatan</span>
                                <v-menu v-model="datepicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="editedItem.doc_date" filled rounded dense hide-details="auto" :rules="reqRule" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.doc_date" @input="datepicker = false"></v-date-picker>
                                </v-menu>
                                
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <span>Nomor Referensi</span>
                                <v-text-field v-model="editedItem.reference_code" filled rounded dense hide-details="auto" :rules="reqRule" :readonly="!isnew"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <span>Bahan Baku</span>
                                <v-select filled rounded dense :items="rawmaterials" item-text="description" item-value="code" v-model="editedItem.material_code" :rules="reqRule" :readonly="!isnew" hide-details="auto"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" v-if="!isnew">
                                <span>Total Penerimaan Supplier</span>
                                <v-text-field v-model="editedItem.total_qty" filled rounded dense hide-details="auto" :rules="reqRule" :suffix="editedItem.uom" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <span>Total Defect</span>
                                <v-text-field type="number" v-model="editedItem.defect_qty" filled rounded dense hide-details="auto" :rules="reqRule" :suffix="editedItem.uom" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <span>Tipe Pencatatan</span>
                                <v-radio-group v-model="editedItem.return" column :rules="reqRuleRadio">
                                    <v-radio label="Retur Stock" :value="true"></v-radio>
                                    <v-radio label="Pencatatan Defect Stock" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog=false">
                    Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        

        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
    </div>    
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            editedItem: {},
            datepicker: false,
            reqRule: [v => !!v || 'Harus diisi!'],
            reqRuleRadio: [v => v!==undefined || 'Harus diisi!'],
            // rawmaterials: [{code: null, description: null}],
            valid: true,
            onProgress: false,
            isnew: false,
        }
    },
    computed: {
        rawmaterials() {
            return this.$store.state.rawmaterial.objs
        }
    },
    async created() {
         await this.$store.dispatch('rawmaterial/getObjs')
    },
    methods: {
        async save() {
            await this.$refs.form.validate();
            if (!this.valid) return;
            console.log(this.editedItem)

            let url = this.$store.state.config.apiHost + "defectStocks"
            let response = await this.$axios.post(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
            console.log(response)
            this.dialog = false
            this.$emit('refresh')
            // if (response.data.status == "success") 
        },
        async openDialogAdd() {
            this.editedItem = {}
            this.isnew = true
            this.dialog = true
            this.onProgress = true
            // this.rawmaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
            this.onProgress = false
        },
        openDialog(obj) {
            this.isnew = false
            console.log(obj)
            this.dialog = true
            // this.rawmaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
            // this.rawmaterials = [{code: obj.material.code, description: obj.material.description}]
            let payload = {
                doc_date: obj.receipt_date,
                reference_code: obj.receipt_no,
                material_code: obj.material.code,
                total_qty: obj.total_qty,
                uom: obj.material.uom,
                defect_qty: null,
                return: true
            }
            this.editedItem = Object.assign({}, payload)
        }
    }
}
</script>