<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
            sort-by="receipt_no"
            :sort-desc="true"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>In-Stock Bahan Baku</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="initialize">
                    Refresh
                </v-btn>
                <v-btn color="primary" dark class="ml-2" @click="dialog=true" v-if="$store.state.currentPagePriv.create">
                  Add New
                </v-btn>
                <v-dialog v-model="dialog" v-if="dialog" max-width="800px">
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <span>Tanggal Penerimaan</span>
                          <v-menu v-model="datepicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="editedItem.receipt_date" filled rounded dense hide-details="auto" :rules="reqRule" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.receipt_date" @input="datepicker = false"></v-date-picker>
                          </v-menu>
                            
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Bahan Baku</span>
                            <v-autocomplete filled rounded dense :items="rawmaterials" item-text="description" item-value="code" v-model="editedItem.material.code" :rules="reqRule" hide-details="auto"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Supplier</span>
                            <v-autocomplete filled rounded dense :items="suppliers" item-text="supplier_name" item-value="code" v v-model="editedItem.supplier.code" :rules="reqRule"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Total Penerimaan Supplier</span>
                            <v-text-field type="number" v-model="editedItem.total_qty" filled rounded dense hide-details="auto" :rules="reqRule" :suffix="fillUom(editedItem.material.code)" @wheel="$event.target.blur()"></v-text-field>
                        </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogCancel" max-width="500px">
                  <v-card>
                      <v-card-title class="body-1 text-center">Proses ini akan mengurangi quantity stock pada Bahan Baku ini. Anda yakin akan membatalkan transaksi in-stock ini?</v-card-title>
                      <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
                      <v-btn color="blue darken-1" text @click="cancelItemConfirm">Yes</v-btn>
                      <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }" v-if="$store.state.currentPagePriv.delete">              
                <v-icon small class="mr-2" @click="cancelItem(item)" v-show="item.stock_updated" v-bind="attrs" v-on="on">
                    mdi-cancel
                </v-icon>
              </template>
              <span>Cancel</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }" v-if="$store.state.currentPagePriv.update">              
                <v-icon small class="mr-2" @click="editItem(item)" v-show="!item.stock_updated" v-bind="attrs" v-on="on">
                    mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">              
                <v-icon small @click="defectStock(item)" v-show="item.stock_updated" v-bind="attrs" v-on="on">
                    mdi-clipboard-arrow-left
                </v-icon>
              </template>
              <span>Defect Stock</span>
            </v-tooltip>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Refresh
            </v-btn>
            </template>
            <template v-slot:[`item.total_qty`]="{ item }">
              {{item.total_qty}} {{item.material.uom}}
            </template>
            <template v-slot:[`item.total_defect_qty`]="{ item }">
              {{item.total_defect_qty}} {{item.material.uom}}
            </template>
            <template v-slot:[`item.stock_updated`]="{ item }">
              {{(item.stock_updated)?'Updated':''}}
            </template>
        </v-data-table>
        <defect ref="defectRef" @refresh="initialize"></defect>
        <v-overlay :value="onProgress" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        
    </div>
</template>

<script>
  import Defect from '../components/Defect.vue'
  export default {
    components: {
      Defect
    },
    data: () => ({
      dialog: false,
      dialogCancel: false,
      headers: [
        { text: 'Nomor Penerimaan', value: 'receipt_no' },
        { text: 'Tanggal', value: 'receipt_date' },
        { text: 'Kode Bahan', value: 'material.code' },
        { text: 'Nama Bahan', value: 'material.description' },
        { text: 'Supplier', value: 'supplier.supplier_name' },
        { text: 'Total Penerimaan', value: 'total_qty', align: 'center' },
        { text: 'Stock Status', value: 'stock_updated' },
        { text: 'Total Defect', value: 'total_defect_qty', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      // today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      defaultItem: {
        material: {},
        supplier: {},
        receipt_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      editedItem: {
        material: {},
        supplier: {},
        receipt_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      search: '',
      suppliers: [],
      categories: [],
      rawmaterials: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true,
      datepicker: false,
      onProgress: false
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New In-Stock Bahan Baku' : 'Edit In-Stock Bahan Baku'
      },
      roles () {
        return this.$store.state.role.objs
      },
      uom () {
        return this.$store.state.rawmaterial.uom
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.onProgress = true
        this.suppliers = (await this.$store.dispatch('supplier/getObjs')).data.data
        this.categories = (await this.$store.dispatch('category/getObjs')).data.data
        this.rawmaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
        let response = await this.$store.dispatch('getObjs', {apidomain:'inStocks', parameter:''})
        console.log(response)
        if (response.data.status=='success') this.items = response.data.data
        this.onProgress = false
      },
      defectStock(obj) {
        this.$refs.defectRef.openDialog(obj)
      },
      getStatus(stat){
        return stat
      },
      fillUom(raw_material_code) {
        if (!raw_material_code) return;
        return (this.rawmaterials.find(element=> {
          return element.code == raw_material_code
        })).uom
      },

      getCategoryName(code) {
        return this.categories.find(element => {
          return element.code == code
        }).category_name;
      },

      getRawMaterialName(code) {
        return this.rawmaterials.find(element => {
          return element.code == code
        }).description;
      },

      getSupplierName(code) {
        if (!code) return;
        return this.suppliers.find(element => {
          return element.code == code
        }).supplier_name;
      },

      cancelItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogCancel = true
      },

      async cancelItemConfirm () {
        this.onProgress = true
        let payload = {stock_updated: false}
        let url = this.$store.state.config.apiHost + "inStocks/" + this.editedItem.receipt_no
        let response = await this.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        if (response.data.status=='success') {
          Object.assign(this.items[this.editedIndex], response.data.data)
          console.log(this.items)
          this.closeDelete()
        }
        this.onProgress = false
      },

      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        console.log(this.editedItem)
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogCancel = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        await this.$refs.form.validate();
        if (!this.valid) return;

        this.onProgress = true

        let payload = {
          receipt_no: this.editedItem.receipt_no,
          receipt_date: this.editedItem.receipt_date,
          total_qty: this.editedItem.total_qty,
          material_code: this.editedItem.material.code,
          supplier_code: this.editedItem.supplier.code,
          stock_updated: true
        }
        if (this.editedIndex > -1) {
          let url = this.$store.state.config.apiHost + "inStocks/" + this.editedItem.receipt_no
          let response = await this.$axios.put(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          console.log(response.data)
          if (response.data.status=='success') {
            this.$nextTick(() => {            
              Object.assign(this.items[this.editedIndex], response.data.data)
            })
          }

          
        } else {
          let url = this.$store.state.config.apiHost + "inStocks"
          let response = await this.$axios.post(url, JSON.stringify(payload), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') {
            console.log(response.data)
            this.items.push(response.data.data)
          }
        }
        this.close()
        this.onProgress = false
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>